@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'DM Sans', sans-serif !important;
        font-feature-settings: 'kern' !important;
        -webkit-font-smoothing: antialiased;
        letter-spacing: -0.5px;
    }
}

input.defaultCheckbox::before {
    content: url(../src/assets/svg/checked.svg);
    color: white;
    opacity: 0;
    height: 16px;
    width: 16px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
    opacity: 1;
}

.line-clamp-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.line-clamp-7 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
}

.search__dropdown {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.search__button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #ff595973;
    border-left: none;
}

.search__button::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: #ff5959;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 0;
}

.homeSlider .swiper-button-prev,
.homeSlider .swiper-button-next {
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 100%;
    background: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.18);
    transition: all .2s;
}

.mySwiper.homeSlider .swiper-button-prev:after,
.swiper-button-next:after,
.mySwiper.homeSlider .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    font-size: 1.2rem;
}

.homeSlider .swiper-button-prev:hover,
.homeSlider .swiper-button-next:hover {
    background: #ededed;
}

.mySwiper.topPurchaseSlider {
    position: static;
}

.mySwiper.topPurchaseSlider .swiper-button-prev {
    top: 5%;
    right: 7%;
    left: auto;
    z-index: 4;
}

.mySwiper.topPurchaseSlider .swiper-button-next {
    top: 5%;
    right: 5%;
    z-index: 4;
}

.mySwiper.topPurchaseSlider .swiper-button-prev:after,
.swiper-button-next:after,
.mySwiper.topPurchaseSlider .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    font-size: 1rem;
}

.hero__wrapper .slick-prev {
    left: 38px !important;
}

.hero__wrapper .slick-next {
    right: 38px !important;
}

.hero__wrapper .slick-prev,
.hero__wrapper .slick-next {
    z-index: 99 !important;
}

.hero__wrapper .slick-prev:before,
.hero__wrapper .slick-next:before {
    font-size: 36px !important;
    font-family: inherit !important;
    opacity: 1 !important;
}

.hero__wrapper .slick-dots {
    bottom: 20px !important;
}

.hero__wrapper .slick-dots li,
.hero__wrapper .slick-dots li button {
    height: 5px !important;
    width: 48px !important;
}

.hero__wrapper .slick-dots li button {
    background: white !important;
    padding: 0 !important;
    border-radius: 5px !important;
}

.hero__wrapper .slick-dots li button:before {
    content: '' !important;
    height: 5px !important;
    line-height: 5px !important;
    width: 48px !important;
    background: red !important;
    opacity: 0 !important;
    font-size: 10px !important;
    border: 1px solid white !important;
    border-radius: 5px !important;
}

.hero__wrapper .slick-dots li.slick-active button:before {
    opacity: 1 !important;
}

/*Top purchase slick*/
.top-purchase__wrapper .slick-prev,
.top-purchase__wrapper .slick-next {
    background: black;
    top: -3.5rem;
    left: auto;
}

.top-purchase__wrapper .slick-prev {
    right: 2rem;
}

.top-purchase__wrapper .slick-next {
    right: 0rem;
}

.top-purchase__wrapper .slick-prev:hover,
.top-purchase__wrapper .slick-prev:focus,
.top-purchase__wrapper .slick-next:hover,
.top-purchase__wrapper .slick-next:focus {
    color: transparent;
    outline: none;
    background: black !important;
}

.top-purchase__wrapper .slick-slider,
.top-purchase__wrapper .slick-list,
.top-purchase__wrapper .slick-track,
.top-purchase__wrapper .slick-slide,
.top-purchase__wrapper .slick-slide>div:first-child {
    height: 100%
}

.top-purchase__wrapper .slick-arrow {
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.top-purchase__wrapper .slick-arrow:before {
    font-size: 28px !important;
    margin-top: 4px !important;
    opacity: 1 !important;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.navSwiper .swiper-slide {
    width: auto !important;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.mySwiper .swiper-button-prev:after,
.swiper-button-next:after,
.mySwiper .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    font-size: 1.7rem;
    font-weight: bold;
    color: black;
}


@media screen and (max-width: 992px) {

    .mySwiper.topPurchaseSlider .swiper-button-prev {
        top: 3%;
        right: 21%;
        left: auto;
    }

    .mySwiper.topPurchaseSlider .swiper-button-next {
        top: 3%;
        right: 15%;
    }

    .homeSlider .swiper-button-prev,
    .homeSlider .swiper-button-next {
        width: 2.3rem;
        height: 2.3rem;
    }

    .mySwiper.homeSlider .swiper-button-prev:after,
    .swiper-button-next:after,
    .mySwiper.homeSlider .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 767px) {
    .mySwiper.topPurchaseSlider .swiper-button-prev {
        top: 3%;
        right: 11%;
        left: auto;
    }

    .mySwiper.topPurchaseSlider .swiper-button-next {
        top: 3%;
        right: 5%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1245px) {
    .mySwiper.topPurchaseSlider .swiper-button-prev {
        top: 3%;
        right: 6%;
        left: auto;
    }

    .mySwiper.topPurchaseSlider .swiper-button-next {
        top: 3%;
        right: 2%;
    }

    .topSellers__wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }

    .topPurchases__wrapper {
        height: 37.5rem !important
    }
}